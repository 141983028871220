*,
::after,
::before {
  box-sizing: border-box;
}

body {
  padding: 0;
  font-family: sans-serif;
  overflow-y: hidden;
}

.center-item {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container--paper {
  padding: 24px;
  margin-bottom: 32px;
}

.container--paper--title {
  width: 100%;
  padding-bottom: 24px;
}
